<template>
  <div class="content">
    <h2 class="content-heading">帮助中心</h2>
    <div class="block block-rounded">
      <div class="block-header block-header-default">
        <h3 class="block-title"><strong>1.</strong> 介绍</h3>
        <div class="block-options">
          <button type="button" class="btn-block-option">
            <i class="si si-question"></i>
          </button>
        </div>
      </div>
      <div class="block-content block-content-full">
        <div id="faq1" role="tablist" aria-multiselectable="true">
          <div class="block block-bordered block-rounded mb-1">
            <div class="block-header" role="tab" id="faq1_h1">
              <a
                class="fw-semibold text-body-color-dark"
                data-bs-toggle="collapse"
                href="#faq1_q1"
                aria-expanded="true"
                aria-controls="faq1_q1"
                >{{ webName }} 有什么<span class="text-primary">作用</span></a
              >
            </div>
            <div
              id="faq1_q1"
              class="collapse show"
              role="tabpanel"
              aria-labelledby="faq1_h1"
              data-bs-parent="#faq1"
            >
              <div class="block-content border-top">
                <p>
                  宥马运动
                  <span class="px-2 text-info">每日运动，学期跑步任务</span
                  ><br /><br />
                  <span class="text-danger"
                    >具体任务请登录账号后以实际为准</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="block block-bordered block-rounded mb-1">
            <div class="block-header" role="tab" id="faq1_h2">
              <a
                class="fw-semibold text-body-color-dark"
                data-bs-toggle="collapse"
                href="#faq1_q2"
                aria-expanded="true"
                aria-controls="faq1_q2"
                >如何在 {{ webName }}
                <span class="text-primary">添加账号</span></a
              >
            </div>
            <!-- <div
              id="faq1_q2"
              class="collapse"
              role="tabpanel"
              aria-labelledby="faq1_h2"
              data-bs-parent="#faq1"
            >
              <div class="block-content border-top">
                <p>
                  <span class="text-primary"
                    >添加账号之前 账号需要拥有配额
                    若功能为付费功能则需充值余额使用</span
                  >
                  <br />
                  <router-link class="text-danger" to="/shop/money">
                    <span class="nav-main-link-name">点我购买</span>
                  </router-link>

                  <br />
                  （1）<span class="text-info">账号密码</span
                  >模式：输入账号和密码即可添加<br />
                </p>
              </div>
            </div> -->
          </div>
          <div class="block block-bordered block-rounded mb-1">
            <div class="block-header" role="tab" id="faq1_h3">
              <a
                class="fw-semibold text-body-color-dark"
                data-bs-toggle="collapse"
                href="#faq1_q3"
                aria-expanded="true"
                aria-controls="faq1_q3"
                >管理账号以及账号状态</a
              >
            </div>
            <div
              id="faq1_q3"
              class="collapse"
              role="tabpanel"
              aria-labelledby="faq1_h3"
              data-bs-parent="#faq1"
            >
              <div class="block-content border-top">
                <p>
                  （1）账号添加后如果账号状态失效，请及时更新状态防止功能不运行<br />

                  <!-- （2）添加一个账号需要一个配额，若需要挂更多账号请购买挂机配额，挂机配额为永久使用 -->
                  <!-- <br /> -->

                  <!-- <router-link class="text-danger" to="/shop/quota">
                    <span class="nav-main-link-name">点我购买</span>
                  </router-link> -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block block-rounded">
      <div class="block-header block-header-default">
        <h3 class="block-title">
          <strong>2.</strong> {{ webName }}
          <span class="text-primary">常见问题</span>
        </h3>
        <div class="block-options">
          <button type="button" class="btn-block-option">
            <i class="si si-question"></i>
          </button>
        </div>
      </div>
      <div class="block-content block-content-full">
        <div id="faq2" role="tablist" aria-multiselectable="true">
          <div class="block block-bordered block-rounded mb-1">
            <div class="block-header" role="tab" id="faq2_h1">
              <a
                class="fw-semibold text-body-color-dark"
                data-bs-toggle="collapse"
                href="#faq2_q1"
                aria-expanded="true"
                aria-controls="faq2_q1"
                ><font class="text-primary">忘记</font>了密码/ 想修改密码
                怎么办？</a
              >
            </div>
            <div
              id="faq2_q1"
              class="collapse"
              role="tabpanel"
              aria-labelledby="faq2_h1"
              data-bs-parent="#faq2"
            >
              <div class="block-content border-top">
                <p>
                  1.忘记密码 ： 在登录页/注册页 点击找回密码 ，
                  填写自己的QQ邮箱，输入验证码后点击找回密码，
                  您的邮箱将会收到一封来自 {{ webName }} 的邮件
                  点击链接重置密码即可。<br />
                  2.修改密码： 在个人资料中 输入原密码 以及新密码 即可更改密码
                  <br />
                  <router-link class="text-danger" to="/user">
                    <span class="nav-main-link-name">点我修改</span>
                  </router-link>
                </p>
              </div>
            </div>
          </div>
          <div class="block block-bordered block-rounded mb-1">
            <div class="block-header" role="tab" id="faq2_h2">
              <a
                class="fw-semibold text-body-color-dark"
                data-bs-toggle="collapse"
                href="#faq2_q2"
                aria-expanded="true"
                aria-controls="faq2_q2"
                >为什么功能没有运行?</a
              >
            </div>
            <div
              id="faq2_q2"
              class="collapse"
              role="tabpanel"
              aria-labelledby="faq2_h2"
              data-bs-parent="#faq2"
            >
              <div class="block-content border-top">
                <p>
                  请检查账号状态是否失效<span class="text-danger">如果失效</span
                  >请检查密码对不对重新添加账号<br />
                  若任务没有运行或没有完成 请点击<span class="text-primary"
                    >立即执行</span
                  >按钮。<br />
                  若有疑问 请联系站长反馈<br />
                  <a
                    target="_blank"
                    href="https://wpa.qq.com/msgrd?v=3&amp;uin=251623126&amp;site=qq&amp;menu=yes"
                    >点我联系</a
                  >
                </p>
              </div>
            </div>
          </div>

          <div class="block block-bordered block-rounded mb-1">
            <div class="block-header" role="tab" id="faq2_h6">
              <a
                class="fw-semibold text-body-color-dark"
                data-bs-toggle="collapse"
                href="#faq2_q6"
                aria-expanded="true"
                aria-controls="faq2_q6"
                ><span class="text-primary">宥马运动</span>如何配置?</a
              >
            </div>
            <div
              id="faq2_q6"
              class="collapse"
              role="tabpanel"
              aria-labelledby="faq2_h6"
              data-bs-parent="#faq2"
            >
              <div class="block-content border-top">
                <p>
                  跑步结束时间
                  ：系统突破时间限制，选择跑步结束的时间，即可在指定时间进行跑步<br />
                  跑步开始时间：该项是通过结束时间-运动数据的总时间=跑步开始时间
                  经过计算而来<br /><br />
                  <span class="text-danger"
                    >提醒：本系统可穿越时间跑步，无视宥马app的运动时间范围</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block block-rounded">
      <div class="block-header block-header-default">
        <h3 class="block-title"><strong>3.</strong> 其他类说明</h3>
        <div class="block-options">
          <button type="button" class="btn-block-option">
            <i class="si si-question"></i>
          </button>
        </div>
      </div>
      <!-- <div class="block-content block-content-full">
        <div id="faq3" role="tablist" aria-multiselectable="true">
          <div class="block block-bordered block-rounded mb-1">
            <div class="block-header" role="tab" id="faq3_h2">
              <a
                class="fw-semibold text-body-color-dark"
                data-bs-toggle="collapse"
                href="#faq3_q2"
                aria-expanded="true"
                aria-controls="faq3_q2"
                >价格问题说明</a
              >
            </div>
            <div
              id="faq3_q2"
              class="collapse"
              role="tabpanel"
              aria-labelledby="faq3_h2"
              data-bs-parent="#faq3"
            >
              <div class="block-content border-top">
                <p>
                  1、宥马运动代跑<span class="text-danger"
                    >具体费用请参考自助商店</span
                  >购买完次数使用
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Faq",
  data() {
    return {
      webName: "Emo-Tool",
    };
  },
};
</script>

<style scoped></style>
